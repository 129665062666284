import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Error.css'; // Assuming you have a separate CSS file

const NotFoundPage = () => {
  return (
    <div className='not-found-container'>
        <div className='not-found-text'>
        <h1>Ups! Nothing to see here...</h1>
      <p>Go back to the main page:</p>
      <Link to="/" className="link-button">Main Page</Link>
        </div>
    </div>
  );
};

export default NotFoundPage;
