import React, { useState} from 'react';
import { Nav, NavDropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import '../css/CustomNavbar.css';

const CustomNavbar = ({ contactRef, scheduleRef, locationRef }) => {
  const scrollToContact = () => {
    if (contactRef && contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollToLocation = () => {
    if (locationRef && locationRef.current) {
      locationRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollToAgenda = () => {
    if (scheduleRef && scheduleRef.current) {
      scheduleRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const { i18n } = useTranslation(); // Use the i18n instance
  const [lang, setLang] = useState('en'); // State to track current language
  const { t } = useTranslation();

  const handleSelect = (eventKey) => {
    setLang(eventKey); // Update state with new language selection
    i18n.changeLanguage(eventKey); // Change the language of the app
  };

  return (
    
    <Nav expand="lg" style={{ backgroundColor: '#F8F4EF', position: 'absolute', top: 0, left: 0, right: 0, zIndex: 1000 }}>
      <Nav className="me-auto" style={{ marginLeft: '15px' }}>
        <NavDropdown
          title={<span className="text-white"><FontAwesomeIcon icon={faBars} size="2x" style={{ color: '#ffab60' }}/></span>}
          className="custom-dropdown"
        >
         <NavDropdown.Item onClick={scrollToContact}>{t('Contact')}</NavDropdown.Item>
          <NavDropdown.Item onClick={scrollToAgenda}>{t('Schedule')}</NavDropdown.Item>
          <NavDropdown.Item onClick={scrollToLocation}>{t('Location')}</NavDropdown.Item>
        </NavDropdown>
      </Nav>
      
      <NavDropdown
        title={<span className="text-white"><FontAwesomeIcon icon={faLanguage} size="2x" style={{ color: '#ffab60' }}/></span>}
        id="nav-dropdown-lang"
        onSelect={handleSelect}
        className="custom-dropdown"
      >
        <NavDropdown.Item eventKey="en">English</NavDropdown.Item>
        <NavDropdown.Item eventKey="es">Español</NavDropdown.Item>
        <NavDropdown.Item eventKey="fr">Français</NavDropdown.Item>
      </NavDropdown>
      </Nav>
  );
};

export default CustomNavbar;
