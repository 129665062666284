import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import '../css/EventInfo.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Gift = () => {
  const { t } = useTranslation();

  const copyToClipboard = async () => {
    try {
      const element = document.querySelector(".user-select-all");
      await navigator.clipboard.writeText(element.textContent);
    //   console.log("Text copied to clipboard!");
      // Optional: Display a success message to the user
    } catch (error) {
    //   console.error("Failed to copy to clipboard:", error);
      // Optional: Display an error message to the user
    }
  };
    const copyIconRef = useRef(null);
    const [tooltipText, setTooltipText] = useState('Copied!');

  
    useEffect(() => {
        const bootstrap = require('bootstrap');
        const tooltip = new bootstrap.Tooltip(copyIconRef.current);
    
        const updateTooltipText = () => {
          tooltip.setContent({ '.tooltip-inner': tooltipText });
        };
    
        updateTooltipText();
    
        return () => {
          tooltip.dispose();
        };
      }, [tooltipText]);
  

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      }}
    >
      <div className="event-info-container">
        <div className="event-info">
          <h3 className="event-date" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '1.5rem' }}>
            {t("Your presence is our most cherished gift ")}
            <FontAwesomeIcon icon={faHeart} />
          </h3>
          <p className="event-date" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '1rem' }}>
            {t("However, for those who would like to contribute to our honeymoon, here are the details:")}
          </p>

          <button type="button" className="button-honeymoon" data-toggle="modal" data-target="#ModalCenter">
          {t("Honeymoon fund")}
          </button>

          <div className="modal fade" id="ModalCenter" tabIndex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="button-honeymoon">{t("Honeymoon fund")}</h5>
                </div>
                <div className="modal-body" id="modal-body-content">
                <span
                ref={copyIconRef}
                className="copy-icon"
                onClick={copyToClipboard}
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-custom-class="custom-tooltip"
                title={t("Copied!")}
                >
                📋</span>
                
                <p class='user-select-all'>
                Ana patricia Gilbon Garcia
                <br />
                IBAN: DE29 1001 1001 2626 9503 82
                <br />
                BIC: NTSBDEB1XXX
                </p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" data-dismiss="modal">{t("Thank you")}</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Gift;