import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import '../css/EventInfo.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Collapse, Button } from 'react-bootstrap';

const GiftDesktop = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const copyToClipboard = async () => {
    try {
      const element = document.querySelector(".user-select-all");
      await navigator.clipboard.writeText(element.textContent);
    //   console.log("Text copied to clipboard!");
      // Optional: Display a success message to the user
    } catch (error) {
    //   console.error("Failed to copy to clipboard:", error);
      // Optional: Display an error message to the user
    }
  };
    const copyIconRef = useRef(null);
    const [tooltipText, setTooltipText] = useState('Copied!');

  
    useEffect(() => {
        const bootstrap = require('bootstrap');
        const tooltip = new bootstrap.Tooltip(copyIconRef.current);
    
        const updateTooltipText = () => {
          tooltip.setContent({ '.tooltip-inner': tooltipText });
        };
    
        updateTooltipText();
    
        return () => {
          tooltip.dispose();
        };
      }, [tooltipText]);
  

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      }}
    >
      <div className="event-info-container">
        <div className="event-info">
          <h3 className="event-date" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '1.5rem' }}>
            {t("Your presence is our most cherished gift ")}
            <FontAwesomeIcon icon={faHeart} />
          </h3>
          <p className="event-date" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '1rem' }}>
            {t("However, for those who would like to contribute to our honeymoon, here are the details:")}
          </p>

          <div className="collapse-message">
          <Button
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
            className="button-honeymoon"
            fontFamily ='Josefin Sans, sans-serif'
          >
            {open ? 'Hide Details' : "Honeymoon fund"}
          </Button>
          <Collapse in={open}>
            <div id="example-collapse-text" className="message-content">
              <div className="modal-body" id="modal-body-content">
              <p></p>
                  <span
                  ref={copyIconRef}
                  className="copy-icon"
                  onClick={copyToClipboard}
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  data-bs-custom-class="custom-tooltip"
                  title={t("Copied!")}
                  >
                  📋</span>
                  <p class='user-select-all' style={{ fontFamily: 'Josefin Sans, sans-serif'}}>
                  Ana patricia Gilbon Garcia
                  <br />
                  IBAN: DE29 1001 1001 2626 9503 82
                  <br />
                  BIC: NTSBDEB1XXX
                  </p>
                  </div>
            </div>
          </Collapse>
        </div>

        </div>
      </div>
    </div>
  );
};

export default GiftDesktop;