import React from 'react';
import '../css/Location.css';
import { useTranslation } from 'react-i18next';

const Location = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <div ref={ref} className="location-container">
      <h1 className="location-title">{t("Location")}</h1>
      <br></br>
      <gmp-map center="37.36903381347656,-5.984835624694824" zoom="15" map-id="DEMO_MAP_ID" style={{width: '40vh'}}>
      <gmp-advanced-marker position="37.36903381347656,-5.984835624694824" title="Villa Luisa"></gmp-advanced-marker>
      </gmp-map>
      <br />
      <h2 className="location-details">Villa Luisa Sevilla</h2>
      <p>Avenida Manuel Siurot 1, 41013<br />Sevilla, Spain</p>
    </div>
  );
});

export default Location;
