import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faTaxi, faHotel, faTrain, faBus, faStar } from '@fortawesome/free-solid-svg-icons';
import '../css/Travel.css'; // Ensure you create and import the CSS for styling
import { useTranslation } from 'react-i18next';

const Travel = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <div ref={ref} className="travel-container">
      <div className="travel-text">
      <h1 className="travel-title">{t("Travel Information")}</h1>
      <div className="event">
      <h2 className="event-title">{t("Getting to ")}Sevilla</h2>
        <hr className="event-divider" />
        <div className="travel-section">
          <div className="icon-text">
            <FontAwesomeIcon icon={faPlane} className="icon" />
            <div className="text">
              <p>{t("Sevilla Airport (SVQ) is the nearest airport to Sevilla. It is around 10km from the city center. We recommend you to get your tickets through ")}<a href="https://www.google.com/flights" target="_blank" rel="noopener noreferrer" className="link">Google Flights</a> or <a href="https://www.skyscanner.com" target="_blank" rel="noopener noreferrer" className="link">Skyscanner</a>.</p>
            </div>
          </div>
        </div>

        <div className="travel-section">
          <div className="icon-text">
            <FontAwesomeIcon icon={faTrain} className="icon" />
            <div className="text">
              <p>{t("Seville has excellent train connections with many cities in Spain. If you take a high-speed train (AVE), you can arrive from Madrid in just two and a half hours, from Cordoba in forty minutes and from Valencia in four hours. We recommend you to purchase the tickets in ")}<a href="https://www.thetrainline.com" target="_blank" rel="noopener noreferrer" className="link">Trainline</a>.</p>
            </div>
          </div>
          </div>
        </div>

        <div className="travel-section">
          <h2 className="event-title">{t("Getting from the airport to your hotel")}</h2>
        <hr className="event-divider" />
          <div className="icon-text">
            <FontAwesomeIcon icon={faBus} className="icon" />
            <div className="text">
              <p>{t("From the airport, you can take a bus to the city center for around 5 euros. The journey takes approximately 35 minutes.")}</p>
            </div>
          </div>
        </div>

        <div className="travel-section">
          <div className="icon-text">
            <FontAwesomeIcon icon={faTaxi} className="icon" />
            <div className="text">
              <p>{t("Alternatively, you can take a taxi. It will cost between 20-30 euros.")}</p>
            </div>
          </div>
        </div>

      <div className="event">
      <h2 className="event-title">{t("Some options to stay")}</h2>
        <hr className="event-divider" />

        <div className="travel-section">
          <div className="icon-text">
            <FontAwesomeIcon icon={faHotel} className="icon" />
            <div className="text">
              <p style={{ fontWeight: 'bold' }}>Hotel Alfonso XIII</p>
              <div className="stars-container">
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
              </div>
              <p><a href='https://maps.app.goo.gl/rbFQnAZgW9Z3kFGc9' target="_blank" rel="noopener noreferrer" className="link">{t("Location")}</a></p>
              <p><a href="https://www.booking.com/Share-HpozvfG" target="_blank" rel="noopener noreferrer" className="link">{t("Book Now")}</a></p>
            </div>
          </div>
        </div>

       <div className="travel-section">
          <div className="icon-text">
            <FontAwesomeIcon icon={faHotel} className="icon" />
            <div className="text">
            <p style={{ fontWeight: 'bold' }}>Hotel Don Ramón</p>
              <div className="stars-container">
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
              </div>
              <p><a href='https://maps.app.goo.gl/6KzeZrCfrXuxrGE2A' target="_blank" rel="noopener noreferrer" className="link">{t("Location")}</a></p>
              <p><a href="https://www.booking.com/Share-Os1CZe" target="_blank" rel="noopener noreferrer" className="link">{t("Book Now")}</a></p>
            </div>
          </div>
        </div>

        <div className="travel-section">
          <div className="icon-text">
            <FontAwesomeIcon icon={faHotel} className="icon" />
            <div className="text">
            <p style={{ fontWeight: 'bold' }}>Querencia de Sevilla</p>
              <div className="stars-container">
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
              </div>
              <p><a href='https://maps.app.goo.gl/hhpYVzoLUsWRRDQi6' target="_blank" rel="noopener noreferrer" className="link">{t("Location")}</a></p>
              <p><a href="https://www.booking.com/Share-LWBMbL" target="_blank" rel="noopener noreferrer" className="link">{t("Book Now")}</a></p>
            </div>
          </div>
        </div>

        <div className="travel-section">
          <div className="icon-text">
            <FontAwesomeIcon icon={faHotel} className="icon" />
            <div className="text">
            <p style={{ fontWeight: 'bold' }}>Soho Boutique Catedral</p>
              <div className="stars-container">
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
              </div>
              <p><a href='https://maps.app.goo.gl/xAMhEP7a8upMNFmr5' target="_blank" rel="noopener noreferrer" className="link">{t("Location")}</a></p>
              <p><a href="https://www.booking.com/Share-FMMlykj" target="_blank" rel="noopener noreferrer" className="link">{t("Book Now")}</a></p>
            </div>
          </div>
        </div>

        <div className="travel-section">
          <div className="icon-text">
            <FontAwesomeIcon icon={faHotel} className="icon" />
            <div className="text">
            <p style={{ fontWeight: 'bold' }}>Hotel Giralda</p>
              <div className="stars-container">
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
              </div>
              <p><a href='https://maps.app.goo.gl/UANiqZ5V6XMGkYhy6' target="_blank" rel="noopener noreferrer" className="link">{t("Location")}</a></p>
              <p><a href="https://www.booking.com/Share-Rl6Wm8" target="_blank" rel="noopener noreferrer" className="link">{t("Book Now")}</a></p>
            </div>
          </div>
        </div>

        <div className="travel-section">
          <div className="icon-text">
            <FontAwesomeIcon icon={faHotel} className="icon" />
            <div className="text">
            <p style={{ fontWeight: 'bold' }}>Meliá Lebreros</p>
              <div className="stars-container">
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
              </div>
              <p><a href='https://maps.app.goo.gl/fq8FB4GKRXNd3Zfh7' target="_blank" rel="noopener noreferrer" className="link">{t("Location")}</a></p>
              <p><a href="https://www.booking.com/Share-QOuImD" target="_blank" rel="noopener noreferrer" className="link">{t("Book Now")}</a></p>
            </div>
          </div>
        </div>

        <div className="travel-section">
          <div className="icon-text">
            <FontAwesomeIcon icon={faHotel} className="icon" />
            <div className="text">
            <p style={{ fontWeight: 'bold' }}>AC Hotel Ciudad de Sevilla by Marriot</p>
              <div className="stars-container">
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
                <FontAwesomeIcon icon={faStar} className="star-icon" />
              </div>
              <p><a href='https://maps.app.goo.gl/QaRxE4yRCYTRFYG76' target="_blank" rel="noopener noreferrer" className="link">{t("Location")}</a></p>
              <p><a href="https://www.booking.com/Share-wvh8dN" target="_blank" rel="noopener noreferrer" className="link">{t("Book Now")}</a></p>
            </div>
          </div>
        </div>

      </div>
      </div>
    </div>
  );
});

export default Travel;
