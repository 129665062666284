// src/components/OurStory.js
import React from 'react';
import '../css/OurStory.css'; // Ensure you have this CSS file in the correct path
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const OurStory = () => {
  const { t } = useTranslation();
  return (
    <div className="our-story-container">
      <h1 className="our-story-title">{t("Our Story Begins")}</h1>
      <br></br>
      <p className="our-story-text">
      {t("Hey there,")} 
      <br></br>
      {t("If you've received this invitation, know that you hold a special place in our hearts. We sincerely hope you can join us for our wedding celebration.")}
      <br></br>
      <br></br>
      {t("Since our loved ones are scattered like confetti, we're keeping everyone updated through our wedding website.")}
      <br></br><br></br>
      {t("Mark your calendar for May 30th, 2025 because we're tying the knot and can't wait to celebrate with our favorite people in the world—you!")}
      <br></br>
      {t("With love, laughter, and a whole lot of 'happily ever after'")}
      <br></br><br></br> 
      <p>{t("Paty & Matt ")}<FontAwesomeIcon icon={faHeart} /></p>
      <br></br>
      <br></br>
      </p>
    </div>
  );
};

export default OurStory;
