import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Layout from './components/Layout';
import Error from './components/Error';

function App() {
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/*" element={<Error />} /> 
      </Routes>
    </Router>
  );
}

export default App;
