import React, { useState, useEffect } from 'react';
import image1 from '../assets/foto1.avif';
import image2 from '../assets/foto2.avif';
import image3 from '../assets/foto3.avif';
import image4 from '../assets/foto4.avif';
import image5 from '../assets/foto5.avif';
import image6 from '../assets/foto6.avif';
import image7 from '../assets/foto7.avif';
import image8 from '../assets/foto8.avif';
import image9 from '../assets/foto9.avif';
import image10 from '../assets/foto10.avif';
import image11 from '../assets/foto11.avif';
import image12 from '../assets/foto12.avif';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const images = [image9, image2, image3, image4, image5, image6, image7, image8, image1, image10, image11, image12];
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    let loadedImagesCount = 0;

    const handleImageLoad = () => {
      loadedImagesCount++;
      if (loadedImagesCount === images.length) {
        setAllImagesLoaded(true);
      }
    };

    const imageLoadPromises = images.map(src => {
      return new Promise(resolve => {
        const img = new Image();
        img.onload = () => {
          handleImageLoad();
          resolve();
        };
        img.src = src;
      });
    });

    Promise.all(imageLoadPromises).then(() => {
      setAllImagesLoaded(true);
    });

    const interval = setInterval(() => {
      if (allImagesLoaded) {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [images]);

  // Function to check if the device is desktop
  const isDesktop = () => {
    if (typeof window !== 'undefined') {
      return window.innerWidth > 1024; // Adjust the width threshold as per your requirement
    }
    return false;
  };

  const backgroundImageUrl = isDesktop() ? image9 : images[currentImageIndex];

  return (
    <LazyLoad height={200} offset={100} once>
      <div
        className="lazy-loaded-background"
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '20px',
          paddingBottom: '80px',
          color: '#fff6ed'
        }}
      >
        <div style={{ textAlign: 'left', zIndex: 1, position: 'relative' }}>
          <h1 style={{ fontSize: '3rem', fontFamily: 'WindSong, cursive', margin: 0 }}>
            {t("Paty & Matt")}
          </h1>
          <p style={{ fontSize: '1.5rem', fontFamily: 'Josefin Sans, sans-serif', margin: 0 }}>
            {t('We are getting married!')}
          </p>
        </div>
      </div>
    </LazyLoad>
  );
};

export default HomePage;