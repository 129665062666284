import React, { useState, useEffect } from 'react';
import '../css/ScrollUpButton.css';

const ScrollUpButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsVisible(scrollTop > 100); // Show button when user scrolls down past 100 pixels
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <button
      className={`scroll-up-button ${isVisible ? 'show' : 'hide'}`}
      onClick={scrollToTop}
    >
      <i className="fas fa-arrow-up">
      ↑
        </i> {/* Font Awesome arrow icon */}
    </button>
  );
};

export default ScrollUpButton;
