import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faUtensils, faPersonDress, faCar } from '@fortawesome/free-solid-svg-icons';
import '../css/Travel.css'; // Ensure you create and import the CSS for styling
import { useTranslation } from 'react-i18next';

const Travel = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <div ref={ref} className="travel3-container">
      <div className="travel-text">
      <h1 className="travel-title">{t("FAQ")}</h1>

      <div className="event">
      <h2 className="event-title">{t("What is the weather like in May in Sevilla?")}</h2>
        <hr className="event-divider" />
        <div className="travel-section">
          <div className="icon-text">
            <FontAwesomeIcon icon={faSun} className="icon" />
            <div className="text">
              <p>{t("The weather in May in Sevilla is typically warm and sunny, with average temperatures ranging from 20°C to 30°C.")}</p>
            </div>
          </div>
        </div>
        </div>

        <div className="travel-section">
          <h2 className="event-title">{t("Do I need a car?")}</h2>
        <hr className="event-divider" />
          <div className="icon-text">
            <FontAwesomeIcon icon={faCar} className="icon" />
            <div className="text">
              <p>{t("You can visit Sevilla without needing a car. Everything is very centric. Even the venue, Villa Luisa, is located in the city center and within walking distance from Plaza de España.")}</p>
            </div>
          </div>
        </div>

        <div className="travel-section">
          <h2 className="event-title">{t("Do you have any special menu if I have a dietary restrictions?")}</h2>
        <hr className="event-divider" />
          <div className="icon-text">
            <FontAwesomeIcon icon={faUtensils} className="icon" />
            <div className="text">
              <p>{t("If you have any special food restrictions or dietary preferences, please fill in the form provided to inform us about your requirements for you and your guest.")}</p>
            </div>
          </div>
        </div>

        <div className="travel-section">
          <h2 className="event-title">{t("What do you recommend as formal attire?")}</h2>
        <hr className="event-divider" />
          <div className="icon-text">
            <FontAwesomeIcon icon={faPersonDress} className="icon" />
            <div className="text">
              <p>{t("Gentlemen, we suggest opting for a sharp suit paired with a classic tie or bowtie. Ladies, feel free to showcase your style in elegant dresses or evening gowns (with a preference for long dresses). As we'll be celebrating in a garden, we don't recommend stilettos.")}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
});

export default Travel;
