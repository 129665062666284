// src/components/Schedule.js
import React from 'react';
import '../css/Schedule.css'; // Ensure you have this CSS file in the correct path
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlassCheers, faBell, faCocktail } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Schedule = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <div ref={ref} className="schedule-container">
      <h1 className="schedule-title">{t("Schedule")}</h1>
      <br></br>
  <div className="event">
    <h1 className="event-date">{t("Thursday 29th May")}</h1>
    <hr className="event-divider" />
    <h2 className="event-title">{t("Pre-Wedding Gathering")}</h2>
    <FontAwesomeIcon icon={faCocktail} className="icon" />
    <h3 className="event-time">{t("Time: 5pm")}</h3>
    <h3 className="event-time">{t("Location")}: {t("TBC")}</h3>
    <h3 className="event-time">{t("Attire: Cocktail")}</h3>
    <p className="event-details">{t("Drinks & laughs with family & friends")}</p>
  </div>

  <div className="event">
    <h1 className="event-date">{t("Friday 30th May")}</h1>
    <hr className="event-divider" />
    <h2 className="event-title">{t("Ceremony")}</h2>
    <FontAwesomeIcon icon={faBell} className="icon" />
    <h3 className="event-time">{t("Time: 5pm")}</h3>
    <h3 className="event-time">{t("Location")}: Villa Luisa Eventos</h3>
    <h3 className="event-time">{t("Attire: Formal")}</h3>
    <p className="event-details">{t("The moment we say 'I do'")}</p>
  </div>

  <div className="event">
    <h1 className="event-date">{t("Friday 30th May")}</h1>
    <hr className="event-divider" />
    <h2 className="event-title">{t("Reception")}</h2>
    <FontAwesomeIcon icon={faGlassCheers} className="icon" />
    <h3 className="event-time">{t("Time:")}{t("Until")} 5am</h3>
    <h3 className="event-time">{t("Location")}: Villa Luisa Eventos</h3>
    <p className="event-details">{t("Cocktail & dinner & your best dance moves")}</p>
  </div>
{/* <Location/> */}

</div>

  );
});

export default Schedule;
