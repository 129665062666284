// src/components/OurStory.js
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../css/Contact.css'; // Ensure you have this CSS file in the correct path
import { useTranslation } from 'react-i18next';

const Contact = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <div ref={ref} className="contact-container">
       <h1 className="contact-title">{t("Contact Information")}</h1>
       <br></br>
      <div className="contact-info">
        <div className="contact-person">
          <h3>Matt</h3>
          <p><FontAwesomeIcon icon={faPhone} /> {t("Phone")}: +33683053515</p>
          <p><FontAwesomeIcon icon={faEnvelope} /> Email: mattramo@gmail.com</p>
          <p><a href="https://wa.me/33683053515" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>Whatsapp {t("here")}</a></p>

        </div>
        <div className="contact-person">
          <h3>Paty</h3>
          <p><FontAwesomeIcon icon={faPhone} /> {t("Phone")}: +34674445682</p>
          <p><FontAwesomeIcon icon={faEnvelope} /> Email: paty.gilbon@me.com</p>
          <p><a href="https://wa.me/522221649796" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>Whatsapp {t("here")}</a></p>
        </div>
      </div>
    </div>
  );
});

export default Contact;
