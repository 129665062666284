import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import Navbar from './Navbar';
import Footer from './Footer';
import EventInfo from './EventInfo';
import OurStory from './OurStory';
import Schedule from './Schedule';
import Travel from './Travel';
import Travel1 from './Travel1';
import FAQ from './FAQ';
import Contact from './Contact';
import ScrollUpButton from './ScrollUpButton';
import HomePage from './HomePage';
import Location from './Location';
import Gift from './Gift';
import GiftDesktop from './GiftDesktop';

const Layout = () => {
  const location = useLocation();
  const contactRef = useRef(null);
  const scheduleRef = useRef(null);
  const locationRef = useRef(null);
  const isRSVPRoute = location.pathname === '/rsvp';

  const isDesktop = () => {
    if (typeof window !== 'undefined') {
      return window.innerWidth > 1024; // Adjust the width threshold as per your requirement
    }
    console.log('is')
    return false;
  };
  return (
    <div>
      {isDesktop() && (
  <div style={{ display: 'flex' }}>
    <div style={{ position: 'fixed', left: 0, top: 0, height: '100vh', width: '50vw', overflowY: 'auto', zIndex: 1 }}>
      {/* This div will only be visible on desktop */}
      <div style={{ margin: '0 auto'}}>
      {!isRSVPRoute && <Navbar contactRef={contactRef} scheduleRef={scheduleRef} locationRef={locationRef}/>}
        {<HomePage />}
      </div>
    </div>
    <div style={{ marginLeft: '50vw', width: '50vw', boxSizing: 'border-box', zIndex: 0, overflowX: 'hidden' }}>
      {/* Content that scrolls */}
      {!isRSVPRoute && <EventInfo />}
      {!isRSVPRoute && <OurStory />}
      {!isRSVPRoute && <Schedule ref={scheduleRef} />}
      {!isRSVPRoute && <Location ref={locationRef}/>}
      {!isRSVPRoute && <Travel />}
      {!isRSVPRoute && <Travel1 />}
      {!isRSVPRoute && <GiftDesktop />}
      {!isRSVPRoute && <FAQ />}
      {/* {!isRSVPRoute && <Tidbits />} */}
      {!isRSVPRoute && <Contact ref={contactRef} />}
      {!isRSVPRoute &&<Footer />}
      {!isRSVPRoute && <ScrollUpButton />}
    </div>
  </div>
)}

      {!isDesktop() && (
        <div>
        {/* Content for non-desktop */}
        {!isRSVPRoute && <Navbar contactRef={contactRef} scheduleRef={scheduleRef} locationRef={locationRef}/>}
        {!isRSVPRoute && <HomePage />}
        {!isRSVPRoute && <EventInfo />}
        {!isRSVPRoute && <OurStory />}
        {!isRSVPRoute && <Schedule ref={scheduleRef} />}
        {!isRSVPRoute && <Location ref={locationRef}/>}
        {!isRSVPRoute && <Travel />}
        {!isRSVPRoute && <Travel1 />}
        {!isRSVPRoute && <Gift />}
        {!isRSVPRoute && <FAQ />}
        {/* {!isRSVPRoute && <Tidbits />} */}
        {!isRSVPRoute && <Contact ref={contactRef} />}
        {!isRSVPRoute &&<Footer />}
        {!isRSVPRoute && <ScrollUpButton />}
        </div>
      )}
    </div>
  );  
};

export default Layout;
