import React from 'react';
import '../css/Footer.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';


const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const isFixedFooter = () => {
    return location.pathname === '/rsvp';
  };


  return (
    <footer className={isFixedFooter() ? 'fixed-footer' : 'regular-footer'}>
      <div className="text-center p-3" style={{ fontFamily: 'Josefin Sans, sans-serif', backgroundColor: '#ffab60', color:'#F8F4EF' }}>
        © {new Date().getFullYear()} {t("Paty & Matt's wedding 2025")}
     </div>
    </footer>
  );
};

export default Footer;
