import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faChurch, faLandmark } from '@fortawesome/free-solid-svg-icons';
import '../css/Travel.css'; // Ensure you create and import the CSS for styling
import { useTranslation } from 'react-i18next';

const Travel = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <div ref={ref} className="travel1-container">
      <div className="travel-text">
      <h1 className="travel-title">{t("What not to miss in Sevilla")}</h1>

      <div className="event">
        <hr className="event-divider" />
        <div className="travel-section">
          <div className="icon-text">
            <FontAwesomeIcon icon={faStar} className="icon" />
            <div className="text">
            <p style={{ fontWeight: 'bold' }}>Alcázar de Sevilla</p>
            <p>{t("This UNESCO World Heritage Site is a stunning royal palace complex with beautiful gardens, intricate architecture, and historical significance. To visit, it is better to take tickets in advance.")}</p>
            <p>{t("Getting there:")} <a href="https://www.google.com/maps?q=Alcázar+of+Seville" target="_blank" rel="noopener noreferrer" className="link">Google Maps</a></p>
            <p><a href="https://www.alcazar-seville-tickets.com/?gad_source=1&gclid=Cj0KCQjwncWvBhD_ARIsAEb2HW8W8iYCqg7nEl2DmapQ4L8EyB8cl8b_nYd_7nXDMpFrqkR6azD9MHEaAuZqEALw_wcB" target="_blank" rel="noopener noreferrer" className="link">{t("Get tickets")}</a></p>
          </div>
          </div>
        </div>

        <div className="travel-section">
          <div className="icon-text">
            <FontAwesomeIcon icon={faChurch} className="icon" />
            <div className="text">
            <p style={{ fontWeight: 'bold' }}>Catedral de Sevilla</p>
              <p>{t("One of the largest Gothic cathedrals in the world, it houses the tomb of Christopher Columbus and offers breathtaking views from its Giralda tower. To visit, it is better to take tickets in advance.")}</p>
              <p>{t("Getting there:")} <a href="https://maps.app.goo.gl/3ZN7iCN1UmozBu748" target="_blank" rel="noopener noreferrer" className="link">Google Maps</a></p>
              <p><a href="https://catedraldesevilla.entradasdemuseos.com/" target="_blank" rel="noopener noreferrer" className="link">{t("Get tickets")}</a></p>
            </div>
          </div>
        </div>

        <div className="travel-section">
          <div className="icon-text">
            <FontAwesomeIcon icon={faLandmark} className="icon" />
            <div className="text">
            <p style={{ fontWeight: 'bold' }}>Plaza de España</p>
              <p>{t("A magnificent square with a semicircular building adorned with colorful ceramic tiles, offering a picturesque backdrop for photos and leisurely strolls.")}</p>
              <p>{t("Getting there:")} <a href="https://maps.app.goo.gl/tqMcAayexh9SXM16A" target="_blank" rel="noopener noreferrer" className="link">Google Maps</a></p>
              <p>{t("Free entry")}</p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
});

export default Travel;
