import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../css/EventInfo.css';
import { PopupButton } from '@typeform/embed-react'

const EventInfo = () => {
  const { t } = useTranslation();
  const eventDate = new Date('2025-05-30T00:00:00');
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(eventDate));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(eventDate));
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateTimeLeft(eventDate) {
    const difference = +eventDate - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60)
      };
    }

    return timeLeft;
  }

  

  return (
    <div
      className='size'
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      }}
    >
    <div className="event-info-container">
      <div className="event-info">
        <h3 className="event-date" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '1.5rem' }}>{t("Friday 30th May, 2025")}</h3>
        <h2 className="event-location" style={{ fontFamily:'WindSong, cursive', fontSize: '2.5rem' }}>{t("Sevilla, Spain")}</h2>
        <div className="countdown" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '1.5rem' }}>
          {Object.keys(timeLeft).length > 0 ? (
            <p className="countdown-time">
              {Object.keys(timeLeft).map((interval) => {
                return (
                  <span key={interval}>
                    {timeLeft[interval]} {t(interval)}{' '}
                  </span>
                );
              })}
            </p>
          ) : (
            <p>{t('Event has started!')}</p>
          )}
        </div>
        <PopupButton id="b6HYZpI2" style={{ fontSize: 20, borderRadius: 10, fontFamily: 'Josefin Sans, sans-serif' }} className="my-button">
      RSVP
    </PopupButton>
      </div>
    </div>
    </div>
  );
};

export default EventInfo;
